import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Nav from './components/LandingNav';
import LandingHeader from './components/LandingHeader';
import HeaderMobile from './components/LandingHeaderMobile';
import Footer from './components/Footer';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const pathsWithHeaderFooter = [
    '/',
    '/home',
    '/platform',
    '/contact-us',
    '/about-us',
    '/address/*',
    '/services/*',
    '/media'
  ];

  const pathsWithLandingHeader = [
    '/about-us',
    '/services',
    '/media',
    '/contact-us'
  ];

  const showLandingHeader = pathsWithLandingHeader.some(path =>
    location.pathname.includes(path)
  );

  const showHeaderFooter = pathsWithHeaderFooter.some(path =>
    matchPath({ path, exact: false }, location.pathname)
  );

  return (
    <div className="main-layout">
      {showHeaderFooter && <Nav />}
      {showHeaderFooter && <HeaderMobile />}
      {showLandingHeader && <LandingHeader />}
      {children}
      {showHeaderFooter && <Footer />}
    </div>
  );
};

export default MainLayout;

export const formatGemNumber = (number) => {
    if (!number) {
        return 0;
    }

    if (number >= 1000) {
        return `${(number / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    return number;
}

export const normalizeAddress = (address) => {
    if (!address) return "";
  
    return address
      .toLowerCase()
      .replace(/\bst\b/g, "street")
      .replace(/\bave\b/g, "avenue")
      .replace(/\brd\b/g, "road")
      .replace(/\bdr\b/g, "drive")
      .replace(/\bblvd\b/g, "boulevard")
      .split(",")[0]  // Take only the main part before the first comma
      .trim();
  };

  export const categorizePlaceType = (googleTypes) => {
    if (!googleTypes || googleTypes.length === 0) return "Unknown";

    const typeMapping = {
      food: ["restaurant", "cafe", "bakery", "bar", "meal_takeaway", "meal_delivery"],
      services: ["bank", "hospital", "pharmacy", "doctor", "laundry", "beauty_salon", "car_repair", "locksmith", "lawyer", "electrician", "insurance_agency"],
      shops: ["shopping_mall", "clothing_store", "shoe_store", "supermarket", "grocery_or_supermarket", "department_store", "book_store", "convenience_store", "electronics_store", "furniture_store", "hardware_store", "jewelry_store", "liquor_store", "pet_store"],
      transit: ["bus_station", "subway_station", "train_station", "taxi_stand", "transit_station", "airport", "parking"],
      leisure: ["amusement_park", "aquarium", "art_gallery", "bar", "casino", "movie_theater", "museum", "night_club", "park", "stadium", "zoo", "spa"]
    };

    for (const [category, types] of Object.entries(typeMapping)) {
      if (googleTypes.some((type) => types.includes(type))) {
        return category;
      }
    }

    return "shops"; // Default if no match is found
  };
import { useRef, useEffect } from 'react';
import { walkIndexFeatures, lifeStyleFeatures, activeIcons } from './constants';
import { ReactComponent as Legend1 } from './assets/legend-score1.svg'
import { ReactComponent as Legend2 } from './assets/legend-score2.svg'
import { ReactComponent as Legend3 } from './assets/legend-score3.svg'

export const lightMapTheme = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: 'labels.text.fill',
    stylers: [
      { visibility: "on" },
      { color: "#000000" },
      { fontSize: "30px" },
    ],
  },
];

export const darkMapTheme = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];

const walkIndexDescription = {
  access: [
    'Public transit over 5 blocks',
    'Within 3-5 blocks from public transit',
    'Within 1-2 blocks from public transit',
  ],
  architecture: [
    'Mostly postwar',
    'Mixed prewar / modern / postwar',
    'Mostly prewar',
  ],
  comfort: [
    'No shading or seating',
    'Shading or seating',
    'Shading and seating',
  ],
  nature: [
    '< 25% tree cover',
    '≈ 50% tree cover',
    '> 50% tree cover',
  ],
  activities: [
    '> 50% vacant land or other',
    '> 50% Residential',
    'Mixed / Commercial residential',
  ],
  quiet: [
    'Heavy traffic, noisy restaurant (> 85dBA) ',
    'Some traffic and human activity (60-80 dBA)',
    'Quiet residential area (40-55 dBA)',
  ],
};

export const mobileWalkIndexDescription = {
  architecture: [
    'Postwar',
    'Mixed',
    'Prewar',
  ],
  comfort: [
    'No Shade or Seat',
    'Shade or Seat',
    'Shade & Seat',
  ],
  nature: [
    '< 25% trees',
    '≈ 50% trees',
    '> 50% trees',
  ],
  activities: [
    'Vacant or Other',
    'Residential',
    'Mixed or Commercial',
  ],
  quiet: [
    'Very noisy',
    'Some noise',
    'Quiet',
  ],
};

export const legendWalkIndexDescription = {
  architecture: [
    '0',
    '<50',
    '>50',
  ],
  comfort: [
    'No shading or seating',
    'Shading or seating',
    'Shading & seating',
  ],
  nature: [
    '0',
    '<50',
    '>50',
  ],
  activities: [
    '0',
    '<50',
    '>50',
  ],
  quiet: [
    '>80',
    '<80 >40',
    '<40',
  ],
};

export const getAttributeDescription = (type) => {
  switch (type) {
    case 'Access':
      return 'Near public transit';
    case 'Architecture':
      return 'Pre-war, postwar and modern';
    case 'Comfort':
      return 'Seating and shading';
    case 'Vibrance':
      return 'Land Use';
    case 'Nature':
      return 'Trees and greenspace';
    case 'Quiet':
      return 'Noise level';
    case 'All':
      return 'All of the Establishments this Neighbour can offer';
    case 'Food':
      return 'Restaurants, cafes, groceries...';
    case 'Shops':
      return 'Clothing and bookstore';
    case 'Services':
      return 'Banks, laundry, urgent care, beauty....';
    case 'Transit':
      return 'Bus, bike, subway, train...';
    case 'Leisure':
      return 'Clubs, movies, fitness, theaters, museum, music...';
    case 'Favorites':
      return 'Check all your favorite establishment';
    default:
      return '';
  }
};

export const getLimit = (distance) => {
  switch (distance) {
    case '1':
      return [0, 15, 15];
    case '2':
      return [0, 50, 50];
    case '3':
      return [0, 100, 100];
    default:
      return;
  }
};

const lifeStyleDescription = (distance, standalone) => {
  // const limit = standalone ? getStandAloneLimit : getLimit(distance);
  const limit = getLimit(distance);

  return [
    `${limit[0]}`,
    `< ${limit[1]}`,
    `≥ ${limit[2]}`,
  ];
};

export const getTypeDescription = (
  currentTab,
  type,
  score,
  distance = '1',
  standalone = false,
  isMobile = false,
) => {
  const scores = score;
  const finalType = type === 'vibrance' ? 'activities' : type;

  const getDescription = (descriptionSource) => {
    return descriptionSource?.[finalType]?.[(scores || 1) - 1];
  };

  return currentTab === 'walkindex'
    ? (isMobile ? getDescription(mobileWalkIndexDescription) : getDescription(walkIndexDescription))
    : lifeStyleDescription(distance, standalone)[scores - 1];
};

export const findColor = (currentTab, mapTab) => {
  const getList =
    currentTab === 'walkindex' ? walkIndexFeatures : lifeStyleFeatures;

  const color = getList.find(
    (tab) => tab.name.toLowerCase() === mapTab?.toLowerCase()
  );
  return color ? color.color : '#57B4F2';
};

export const getEstablishmentColor = (type) => {
  if (!type) return '#57B4F2';
  const color = lifeStyleFeatures.find(
    (tab) => tab.name.toLowerCase() === type?.toLowerCase()
  );
  return color ? color.color : '#57B4F2';
};

export const getWalkIndexWording = (score) => {
  if (score >= 1 && score < 2) {
    return 'Meh Walkable';
  } else if (score >= 2 && score < 3) {
    return 'Yeah Walkable';
  } else {
    return 'Wow Walkable';
  }
};

export const getLifeStyleWording = (score, distance) => {
  const limit = getLimit(distance);

  if (score >= 0 && score <= limit[0]) {
    return 'Meh Walkable';
  } else if (score <= limit[1]) {
    return 'Yeah Walkable';
  } else {
    return 'Wow Walkable';
  }
};

export const getLifeStyleScore = (score, distance, standalone = false) => {
  const limit = standalone ? getStandAloneLimit : getLimit(distance);
  if ((score >= 0 && score <= limit[0]) || !score) {
    return 1;
  } else if (score <= limit[1]) {
    return 2;
  } else {
    return 3;
  }
};

export const lifeStyleFeaturesScores = (lifeStyleScores, index, currentDistance, isStandalone) => {
  if (!lifeStyleScores) return {};

  const categories = ['food', 'services', 'shops', 'transit', 'leisure'];
  const essentialScores = {};

  categories.forEach((category) => {
    const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
    essentialScores[category] = getLifeStyleScore(
      lifeStyleScores?.[index]?.[type] ?? lifeStyleScores[type],
      currentDistance,
      isStandalone
    );
  });

  return essentialScores;
};

export const isFeelScoreEmpty = (data) => {
  delete data.area;

  return Object.values(data).every(value => value === null || value === "");
}

export const getSoloFeelScore = (score) => {
  const currentScore = score || 1;

  if (currentScore > 2.1) return 3; // Score above 2.1
  if (currentScore > 1.5) return 2; // Score above 1.5 but 2.1 or below
  return 1; // Score 1.5 or below
};

export const getOverallFeelScore = (score) => {
  if (!score && isFeelScoreEmpty(score)) return 1;

  const { activities, beautym, beautyn, comfort, noise } = score;

  const scores = [
    parseFloat(activities),
    parseFloat(beautym),
    parseFloat(beautyn),
    parseFloat(comfort),
    parseFloat(noise),
  ];
  
  // Count the number of scores above 2.1
  const highScoresCount = scores.filter(score => score > 2.1).length;

  if (highScoresCount >= 2) return 3;  // At least 2 scores above 2.1
  if (highScoresCount === 1) return 2; // Only 1 score above 2.1
  return 1;                            // No scores above 2.1
};


export const getOverallGemScore = (score, currentDistance, isStandalone) => {
  if (!score) return 1;

  const categories = ['Food', 'Services', 'Shops', 'Transit', 'Leisure'];
  const essentialScores = {};

  categories.forEach((category) => {
    const type = category === 'Leisure' ? 'leisure' : category.toLowerCase();
    essentialScores[category] = getLifeStyleScore(
      score[type],
      currentDistance,
      isStandalone
    );
  });

  const scores = Object.values(essentialScores);
  const counts = [0, 0, 0];
  scores.forEach((score) => {
    const roundedScore = Math.round(score);
    counts[roundedScore - 1]++;
  });

  let overallGemScore;
  if (counts[2] >= 1) overallGemScore = 3;
  else if (counts[1] >= 1) overallGemScore = 2;
  else overallGemScore = 1;

  return overallGemScore;
};

export const getZoom = (distance) => {
  switch (distance) {
    case '1':
      return 17;
    case '2':
      return 16;
    case '3':
      return 15;
    default:
      return;
  }
};

export const getStandAloneLimit = [5, 15];

export const getZoomMobile = (distance) => {
  switch (distance) {
    case '1':
      return 15.5;
    case '2':
      return 14.5;
    case '3':
      return 14;
    default:
      return;
  }
};

export const getZoomMini = (distance) => {
  switch (distance) {
    case '1':
      return 16;
    case '2':
      return 15;
    case '3':
      return 14;
    default:
      return;
  }
};

export const getZoomLifeStyle = (distance) => {
  switch (distance) {
    case '1':
      return 16;
    case '2':
      return 15;
    case '3':
      return 14;
    default:
      return;
  }
};

export const getArea = (distance) => {
  switch (distance) {
    case '1':
      return 'quarter-mile';
    case '2':
      return 'half-mile';
    case '3':
      return 'one-mile';
    default:
      return;
  }
};

export const getTotalGems = (lifeStyleScores) => {
  return Object.values(lifeStyleScores).reduce(
    (acc, count) => acc + count,
    0
  );
};

export const convertMarkers = (markers) => {
  if (!markers || typeof markers !== 'string') {
    return Array.isArray(markers) ? markers : [];
  }

  const startIndex = markers.indexOf('[');
  const endIndex = markers.lastIndexOf(']');

  if (startIndex !== -1 && endIndex !== -1) {
    return JSON.parse(markers.substring(startIndex, endIndex + 1));
  } else {
    return [];
  }
};

export const shareAddress = (enqueueSnackbar, address) => {
  const currentUrl = `https://senseofplace.ai/?address=${address}`;
  navigator.clipboard
    ?.writeText(currentUrl)
    .then(() => {
      enqueueSnackbar('Link copied to be shared!', {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
    })
    .catch((error) => {
      console.error('Error copying URL to clipboard:', error);
    });
};

export const saveAddressToFavorites = (
  action,
  currentAddress,
  savedAddresses,
  dispatch,
  enqueueSnackbar,
) => {
  const isSaved = savedAddresses?.some((address) => {
    return address.property.toLocaleLowerCase() === currentAddress.property.toLocaleLowerCase();
  });

  if (action === 'save' && !isSaved) {
      const updatedAddresses = [...savedAddresses, currentAddress];
      setLSData('addresses', updatedAddresses);
      dispatch({
          type: 'SET_SAVED_ADDRESSES',
          payload: updatedAddresses,
      });
      enqueueSnackbar('Added to your Favorites', {
          variant: 'info',
          preventDuplicate: true,
          autoHideDuration: 2000,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
  } else if (action === 'remove' && isSaved) {
      const updatedAddresses = savedAddresses.filter(
          (address) => address.property !== currentAddress.property
      );
      setLSData('addresses', updatedAddresses);
      dispatch({
          type: 'SET_SAVED_ADDRESSES',
          payload: updatedAddresses,
      });
      enqueueSnackbar('Address removed from your Favorites', {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
  }
};

export const saveAddressToRecentlyViewed = (
  currentAddress,
  addresses,
  dispatch,
) => {
  const isSaved = addresses?.some((address) => {
    return address?.property?.toLocaleLowerCase() === currentAddress?.property?.toLocaleLowerCase()
  });

  if (!isSaved) {
    const updatedAddresses = [...addresses, currentAddress];
    setLSData('recenlty-viewed', updatedAddresses);
    dispatch({
        type: 'SET_RECENTLY_VIEWED_ADDRESSES',
        payload: updatedAddresses,
    });
  }
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getTabColor = (isWalkindx, mapTab) => {
  const selectedFeatures = isWalkindx ? walkIndexFeatures : lifeStyleFeatures;

  const selectedColor = selectedFeatures
    .filter(
      (feature) =>
        feature.name.toLocaleLowerCase() === mapTab?.toLocaleLowerCase() ||
        feature.id.toLocaleLowerCase() === mapTab?.toLocaleLowerCase()
    )
    .map((feature) => feature.color)[0];

  return { '--tab-color': selectedColor };
};

export const getDescription = (isWalkindx, mapTab) => {
  const getList = isWalkindx ? walkIndexFeatures : [];

  const getType = getList.find(
    (tab) => tab.name.toLowerCase() === mapTab?.toLowerCase()
  );

  return getType ? getType.type : 'Establishments';
}

export const scoreCircleStyle = (score) => {
  let scoreValue = 0;
  if (score === 1) {
    scoreValue = 0.25;
  } else if (score === 2) {
    scoreValue = 0.5;
  } else {
    scoreValue = 1;
  }
  return { '--score-value': scoreValue };
};

export const getFeatureIcon = (isWalkindx, mapTab) => {
  const selectedFeatures = isWalkindx ? walkIndexFeatures : lifeStyleFeatures;

  const selectedIcon = selectedFeatures.filter((feature) => {
    return feature.name.toLocaleLowerCase() === mapTab?.toLocaleLowerCase() ||
      feature.id.toLocaleLowerCase() === mapTab?.toLocaleLowerCase();
  })[0]?.svg;

  return selectedIcon;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj || {})?.length === 0 && obj?.constructor === Object;
};

export const areObjectPropertiesNull = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      return false;
    }
  }
  return true;
};

export const isValidEmail = (email) => {
  // Regular expression for validating email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  // Regular expressions for each requirement
  const lengthRegex = /.{8,}/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numericRegex = /[0-9]/;
  const specialCharRegex = /[$&+,:;=?@#|'<>.^*()%!-]/;

  // Check if all requirements are met
  const violations = [];

  if (!lengthRegex.test(password)) {
    violations.push('Password must be at least 8 characters long.');
  }
  if (!uppercaseRegex.test(password)) {
    violations.push('Password must contain at least one uppercase letter.');
  }
  if (!lowercaseRegex.test(password)) {
    violations.push('Password must contain at least one lowercase letter.');
  }
  if (!numericRegex.test(password)) {
    violations.push('Password must contain at least one numeric digit.');
  }
  if (!specialCharRegex.test(password)) {
    violations.push('Password must contain at least one special character.');
  }

  // Return true if all requirements are met, false otherwise
  return violations;
};

export const setLSData = (key, value) => {
  typeof value === 'object'
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.setItem(key, value);
};

export const getLSData = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return localStorage.getItem(key);
  }
};

export const setSessionData = (key, value) => {
  typeof value === 'object'
    ? sessionStorage.setItem(key, JSON.stringify(value))
    : sessionStorage.setItem(key, value);
};

export const getSessionData = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch {
    return sessionStorage.getItem(key);
  }
};

export const getCookieValue = (name) => {
  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(name));

  if (cookie) {
    return cookie.split('=')[1];
  } else {
    return null;
  }
};

export const setCookieValue = (name, value, maxAgeInSeconds) => {
  const maxAge = maxAgeInSeconds ? `; max-age=${maxAgeInSeconds}` : '';
  document.cookie = `${name}=${value}${maxAge}; path=/`;
};

export const useAutoLogout = (
  logoutTime = 30 * 60 * 1000,
  logoutCallback,
  isInTrialMode,
) => {
  const logoutTimerRef = useRef(null);

  const startLogoutTimer = () => {
    logoutTimerRef.current = setTimeout(() => {
      // Perform logout actions when the timer expires
      logoutCallback();
    }, logoutTime);
  };

  const resetLogoutTimer = () => {
    clearTimeout(logoutTimerRef.current);
    startLogoutTimer();
  };

  // Start the logout timer on initial component mount
  useEffect(() => {
    if (isInTrialMode) {
      startLogoutTimer();

      return () => {
        clearTimeout(logoutTimerRef.current);
      };
    }
  }, [isInTrialMode]);

  return resetLogoutTimer;
};

export const convertTo12HourFormat = (time) => {
  if (!time) return null;
  const hours = parseInt(time?.substring(0, 2), 10);
  const minutes = parseInt(time?.substring(2), 10);

  let meridiem = 'AM';
  let adjustedHours = hours;

  if (hours >= 12) {
    meridiem = 'PM';
    adjustedHours = hours === 12 ? 12 : hours - 12;
  }
  return `${adjustedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${meridiem}`;
};

export const isOpen24Hours = (opening_hours) => {
  if (!opening_hours) return null;

  return opening_hours?.periods?.some((period) => {
    return (
      period.open &&
      period.open.time === '0000' &&
      period.close &&
      period.close.time === '2359'
    );
  });
};

export const formatNumber = (value) => {
  if (value === null) {
    return '0.0';
  }

  const isWholeNumber = Number.isInteger(value);
  return isWholeNumber ? `${value}.0` : `${value}`;
};

export const convertYelpTimeFormat = (time) => {
  if (time) return;
  // Split the input into days and times
  const parts = time?.split('; ');

  // We'll focus on the first part (assuming it's the weekday schedule)
  const weekdaySchedule = parts?.[0]?.split(' ')[1];

  // Split the schedule into opening and closing times
  const [openingTime, closingTime] = weekdaySchedule?.split('-');

  // Convert 24-hour format to 12-hour format
  const convertTo12Hour = (hour) => {
    const [hours, minutes] = hour?.split(':')?.map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours} ${period}`;
  };

  // Convert opening and closing times
  const formattedOpeningTime = convertTo12Hour(openingTime);
  const formattedClosingTime = convertTo12Hour(closingTime);

  // Combine the results
  return `${formattedOpeningTime} - ${formattedClosingTime}`;
}

export const getActiveIcon = (currentTab, score) => {
  const selectedIcons = activeIcons.find((iconType) =>
    iconType.type === currentTab.toLowerCase()
  );

  if (!selectedIcons) {
    return null;
  }

  const IconComponent = selectedIcons.icons[score - 1] || null;

  if (!IconComponent) {
    return null;
  }

  return <IconComponent />;
};

export const hexToRgb = (hex) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

export const getFeelLineLegend2 = (score) => {
  const lines = [Legend1, Legend2, Legend3]
  const IconComponent = lines[score - 1] || null
  return IconComponent ? <IconComponent /> : null
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const getRadius = (distance) => {
  switch (distance) {
    case '1':
      return 402.34;
    case '2':
      return 804.68;
    case '3':
      return 1209.34;
    default:
      return 402.34;
  }
}